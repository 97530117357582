import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { ShowcaseHeader } from './../../elements/ShowcaseElements';

const Showcase = () => {

    const { allContentfulAsset } = useStaticQuery(graphql`
        query showcaseImage {
            allContentfulAsset(filter: {
                title: {
                    eq: "header"
                }
            }){
                edges {
                    node {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `)

    return (
        <ShowcaseHeader style={{backgroundImage: `url(${allContentfulAsset.edges[0].node.file.url})`}}>
            <div className="container">
                <div className="showcase-content">
                    <h2>Hi! my name is<br/> Prince</h2>
                    <h3>Web Developer / Digital Marketer / UX Designer</h3>
                    <p>Full Stack Web Developer with 3+ years’ of experience and BSc Computer Science Degree from Pearson Institute.</p>
                    <p>Building usable, responsive, interactive and fast web applications with the latest web technologies is what I do on a daily basis.</p>
                </div>
                <div className="footer-content">
                    <div className="contact-box">
                        <h3>Email</h3>
                        <p>hello@rathupap.co.za</p>
                    </div>
                    <div className="contact-box">
                        <h3>Location</h3>
                        <p>Johannesburg, South Africa</p>
                    </div>
                    <div className="contact-box">
                        <h3>Follow Me</h3>
                        <div className="social-icons">
                            <a href="https://twitter.com/ke_princie" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href="https://www.instagram.com/princie.js/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="https://linkedin.com/in/prince-rathupa-785725143/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                            <a href="https://github.com/rathupap/" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faGithub} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </ShowcaseHeader>
    )

}

export default Showcase;