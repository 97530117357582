import styled from 'styled-components';

export const ShowcaseHeader = styled.header`

    background-size: cover;
    background-position: center center;
    position: relative;

    @media (max-width: 1024px){

        .container {
            padding-left: 2rem;
            padding-right: 2rem;
        }

    }

    & + footer {
        display: none;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${ props => props.theme.darkColor };
        opacity: 0.7;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: calc(100vh - 121px);
        text-align: left;
        color: ${ props => props.theme.lightColor };
        position: relative;
        z-index: 2;

        @media (max-width: 768px){
            height: calc(100vh - 140px);
        }

        .showcase-content {
            width: 46%;
            h3 {
                font-size: 1rem;
                color: ${ props => props.theme.primaryColor };
                font-weight: 300;
                font-style: italic;
                margin: 1.2rem 0;
            }

            @media (max-width: 768px){
                width: 100%;
            }

            h2 {
                font-size: 3rem;
                margin: 0;
            }

            p {
                font-size: 1.2rem;
                margin: 0 0 1.2rem;
            }
        }

        .footer-content {
            display: flex;
            justify-content: flex-start;
            margin-top: 3rem;

            .contact-box{

                margin-right: 2rem;

                h3 {
                    color: ${ props => props.theme.lightColor };
                    margin: 0 0 0.3rem;
                    font-size: 1.2rem;
                    font-weight: 900;
                    font-style: normal;
                }

                p {
                    font-size: 0.9rem;
                }
            }
            
        }

    }

    @media (max-width: 500px){

        .container {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            height: calc(100vh - 124px);

            h2 {
                font-size: 1.8rem !important;
            }

            .showcase-content {

                p {
                    font-size: 1rem;
                }

            }

            .footer-content {
                flex-wrap: wrap;

                .contact-box {
                    flex: 40%;
                    margin-right: 0;
                    margin-bottom: 1rem;
                }

            }
        }

    }

`;