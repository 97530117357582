import React from "react"
import Layout from './../components/shared/Layout';
import Showcase from './../components/home/Showcase';

const HomePage = () => (
  <Layout>
      <Showcase />
  </Layout>
)

export default HomePage;
